import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Information from "../components/Information";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tutorials"
    }}>{`Tutorials`}</h1>
    <p>{`List of useful examples with JavaScript API. `}</p>
    <br />
    <h2 {...{
      "id": "custom-session-start-trigger"
    }}>{`Custom session start trigger`}</h2>
    <hr></hr>
    <p>{`If you need a custom trigger for a starting a session you can customize default tracking script. To make it works, add your conditions before calling `}<inlineCode parentName="p">{`__ls("newPageView")`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("init", "YOUR_TRACK_ID", { keystrokes: true });
// sample condition
if (false === MY_API.isUserType("Admin")) {
    __ls("newPageView");
}
`}</code></pre>
    <Information color="warning" mdxType="Information">
You can also use newPageView() method <a href="/javascript-api/recording-conditions/">conditions</a>.
    </Information>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "tracking-users-across-multiple-subdomains"
    }}>{`Tracking users across multiple subdomains`}</h2>
    <hr></hr>
    <p>{`To track users across multiple subdomains you have to set a `}<inlineCode parentName="p">{`rootHostname`}</inlineCode>{` option to `}<inlineCode parentName="p">{`.your-domian.com`}</inlineCode>{` (note that the first character is a dot) when calling `}<a parentName="p" {...{
        "href": "/javascript-api/methods#init"
      }}><inlineCode parentName="a">{`init`}</inlineCode></a>{` or `}<a parentName="p" {...{
        "href": "/javascript-api/methods#setoptions"
      }}><inlineCode parentName="a">{`setOptions`}</inlineCode></a>{` method.
:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("init", "YOUR_TRACK_ID", { rootHostname: ".your-domian.com" });
__ls("newPageView");
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "excluding-senstivie-page-content"
    }}>{`Excluding senstivie page content`}</h2>
    <hr></hr>
    <p>{`You can add a `}<inlineCode parentName="p">{`data-ls-disabled`}</inlineCode>{` attribute to exclude an HTML element. This attribute will anonymize the content (text will be replaced with asterisks).`}</p>
    <p>{`The attribute is applied to all child elements, too. In the case of regular clicks and rage clicks, clicking on an anonymized element won’t reveal the text value.`}</p>
    <p>{`Anonymize whole text content inside `}<inlineCode parentName="p">{`.user-info`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div data-ls-disabled class="user-info">
    <p>Logged as: <span>John Doe</span></p>
</div>
`}</code></pre>
    <p>{`Anonymize only sensitive element:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="user-info">
    <p>Logged as: <span data-ls-disabled>John Doe</span></p>
</div>
`}</code></pre>
    <p>{`You can also use `}<inlineCode parentName="p">{`data-ls-enabled`}</inlineCode>{` to make content visible. Useful if some child elements could be visible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div data-ls-disabled class="article">
  <p>Lorem ipsum sensitive article.</p>
  <button data-ls-enabled>Subscribe</button>
</div>
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "integrate-with-other-softwares"
    }}>{`Integrate with other softwares`}</h2>
    <hr></hr>
    <p>{`You can simple integrate LiveSession with other softwares by using `}<a parentName="p" {...{
        "href": "/javascript-api/methods#getSessionURL"
      }}><inlineCode parentName="a">{`getSessionURL`}</inlineCode></a>{` method:`}</p>
    <p><strong parentName="p">{`Google Analytics`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("getSessionURL", function(url, isNewSession){
    if(isNewSession){
        ga('send', {
            hitType: 'event',
            eventCategory: 'LiveSession recording',
            eventAction: url,
            nonInteraction: 1
        })
    }
})
`}</code></pre>
    <p><strong parentName="p">{`LiveChat`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("getSessionURL", function(url, isNewSession){
    if(isNewSession){
        LC_API.set_custom_variables([{name: "LiveSession", value: url}]);
    }
})
`}</code></pre>
    <p><strong parentName="p">{`Segment`}</strong>{` :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("getSessionURL", function(url, isNewSession){
    if(isNewSession){
        analytics.track('LiveSession Recording', {url: url});
    }
})
`}</code></pre>
    <p>{`Create your own integration and let us know at `}<a parentName="p" {...{
        "href": "mailto:hello@livesession.io"
      }}>{`hello@livesession.io`}</a></p>
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      